import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import axios from 'axios'
import serverURL from '../../server-config'
import { FaAngleRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Loading from '../components/Loading';
import { FaHome } from "react-icons/fa";
function HospitalsList() {
    const [data, setData] = useState([]);
    const [city, setCity] = useState('');
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        let fetchData = async () => {
            await axios.get(`${serverURL}/hospitals`).then((result) => {
                setData(result.data.filter(item => item.hospitalId !== 'HH245107036' && item.hospitalDetails.address.city.toLowerCase().includes(city)));
                setLoading(false);
            }).catch((err) => {
                console.log('error ', err)
            });
        }
        fetchData();
    }, [city])
    return (
        <div>
            <Navbar />
            { !loading && <div className='p-4 flex flex-col gap-2 lg:gap-5 h-[90vh] overflow-scroll'>
                <Link to='../' className='flex items-center gap-2 '><FaHome />Home</Link>
                <div className='text-sm lg:text-xl w-1/2 lg:w-4/5 mx-auto text-center'><span className='text-green'>Healthkard</span> is now partnered with following hospitals</div>
                <div className='mx-auto'>
                    <label htmlFor='cities' className='flex gap-2 items-center'>
                        Select your city
                        <select id='cities' onChange={ e => setCity(e.target.value) }>
                            <option>--select--</option>
                            <option value='guntur'>Guntur</option>
                            <option value='narasaraopet'>Narasaraopet</option>
                            <option value='vijayawada'>Vijayawada</option>
                        </select>
                    </label>
                </div>
                { data && data.map((hospital, index) => {
                    return (
                        <div key={ index } className='w-full lg:w-4/5  mx-auto flex  border shadow-md rounded-lg p-2 items-center gap-2'>
                            <div className='w-1/5 lg:w-1/6 flex justify-center items-center'>
                                <img src={ hospital.mediaDetails.logoURL } className='mx-auto lg:mx-0 w-[50px] md:w-[100px] h-[50px] md:h-[100px] rounded-md shadow-md' alt='logo' />
                            </div>
                            <div className='p-2 flex flex-col w-10/12 lg:w-4/5'>
                                <div className='text-md lg:text-xl text-blue font-bold'>{ hospital.hospitalDetails.hospitalTradeName }</div>
                                <div className='text-xs lg:text-sm w-full gap-2'>{ hospital.hospitalDetails.address.street },
                                    { hospital.hospitalDetails.address.landmark },<br className='md:hidden' />
                                    { hospital.hospitalDetails.address.city },
                                    { hospital.hospitalDetails.address.code },<br className='md:hidden' />
                                    { hospital.hospitalDetails.address.state },
                                    { hospital.hospitalDetails.address.country } </div>
                            </div>
                            <Link to={ `/hospital/${hospital.hospitalId}` } className=' p-2 hover:bg-gray-200 rounded-full hover:cursor-pointer'><FaAngleRight /></Link>
                        </div>
                    )
                }) }
            </div> }
            { loading && <div className='h-[90vh] flex justify-center items-center'><Loading /></div> }
        </div>
    )
}

export default HospitalsList