import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom';
import serverURL from '../../server-config'
import { FaBackward } from "react-icons/fa6";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from '../../firebase-config';
function UserInfo() {
  const [verifyBtn, setVerifyBtn] = useState(true);
  const [otpBtn, setOtpBtn] = useState(false);
  const [verified, setVerified] = useState(false);
  const [sending, setSending] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [userEnteredOtp, setUserEnteresOtp] = useState('');
  const { healthId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  useEffect(() => {
    let fetchData = async () => {
      axios.get(`${serverURL}/getUser/${healthId}`).then((result) => {
        setUser(result.data);
      })
      setVerified(false);
      setVerifyBtn(true);
      setOtpBtn(false);
    }
    fetchData();
  }, [healthId]);
  const [u, setU] = useState(null);
  const sendOTP = async () => {
    // setSending(true);
    setVerified(false)
    setVerifyBtn(false);
    setOtpBtn(true);
    // setSending(false);
    return;
    try {
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha", { 'size': 'invisible' });
      const confirmation = await signInWithPhoneNumber(auth, '+' + user.number, recaptcha);
      if (confirmation) {
        toast.success("Otp sent successfully")
        document.getElementById('recaptcha').style.display = 'none';
        setU(confirmation);
        setOtpBtn(true);
        setSending(false);
      }
    } catch (error) {
      toast.error('Too many request from the user');
      setSending(false);
    }
  }

  const verifyOtp = async () => {
    // setVerifying(true);
    setOtpBtn(false);
    setVerified(true);
    // setVerifying(false);
    return;
    try {
      await u.confirm(userEnteredOtp);
      toast.success("Otp verified successfully")

    } catch (error) {
      toast.error("Please re-enter the correct otp");
      setVerifying(false);
      setOtpBtn(true);
    }
  }
  const proceed = async () => {
    try {
      const hospitalId = localStorage.getItem('hospitalId');

      // Fetch the name of the hospital
      const { data: hospitalName } = await axios.get(`${serverURL}/getName/${hospitalId}`);

      console.log({
        hospitalId: hospitalId,
        hospitalName: hospitalName
      })
      await axios.put(`${serverURL}/visited/${healthId}`, {
        hospitalId: hospitalId,
        hospitalName: hospitalName
      }).then((result) => {
        toast.success("Successfully added.");
        navigate('../');
      }).catch((err) => {
        toast.error("Error while updating");
        return;
      });

    } catch (error) {
      console.error("Error adding visit:", error);
      toast.error("Failed to add visit.");
    }
  };

  const cacel = () => {
    navigate('../');
  }
  return (
    <div className='card shadow-lg p-4 rounded flex lg:flex-row flex-col justify-center items-center gap-4 bg-white z-10'>
      <ToastContainer />
      <div className='flex flex-col items-center gap-2  w-3/12 lg:w-1/3'>
        <Link to='../' className='flex items-center gap-4 hover:cursor-pointer'><FaBackward />back</Link>
        <img src={ user.image } className='aspect-square rounded-full	' alt='userprofile' />
        <div className='font-bold'>{ user.healthId }</div>
      </div>
      <div className=' flex flex-col gap-2 w-2/3'>
        <div className=''>{ user.name }</div>
        <div className='flex gap-2'>
          <div className=''>{ user.gender }  </div>
          <div className=''> { user.age }</div>
        </div>
        <div className=''>{ user.number }</div>
        <div className=''>{ user.email }</div>
        <div className='flex'>{ user.address }, { user.city }, { user.pincode }</div>
        <div className='font-bold'>Membership Status</div>
        <div id='recaptcha'></div>
        { verifyBtn && !otpBtn && <div onClick={ sendOTP } className='hover:cursor-pointer blue text-white text-center rounded p-1 font-bold'>Verify</div> }
        { otpBtn && !verified && <div className='flex w-full gap-2'>
          <input onChange={ (e) => setUserEnteresOtp(e.target.value) } type='text' placeholder='Enter otp' className='p-1 w-1/2 border' />
          <div onClick={ verifyOtp } className='blue text-white w-1/2 flex items-center justify-center rounded-md hover:cursor-pointer'>Verify</div>
        </div> }
        { !otpBtn && verified && <div className='text-green text-xl font-bold'>Verified
          <div className='flex gap-2 font-normal'><div className=''>Valid till : </div>{ user.paymentStatus ? (new Date(user.expireDate)).toISOString().split('T')[0] : 'INVALID' }</div>
          <div className='flex justify-between text-md font-normal'>
            <div onClick={ cacel } className='hover:cursor-pointer w-fit lg:w-2/5 flex justify-center items-center  bg-red-700 p-1 text-white rounded-md text-xs lg:text-xl'>Cancel</div>
            <div onClick={ proceed } className='hover:cursor-pointer w-fit lg:w-2/5 flex justify-center items-center blue p-1 text-white rounded-md text-xs lg:text-xl'>Ok! Proceed</div>
          </div>
        </div> }
        { sending && <div className=''>Please wait Sending otp...</div> }
        { verifying && <div className=''>Please wait Verifying otp...</div> }
      </div>
    </div>
  )
}

export default UserInfo